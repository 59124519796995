
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { defineComponent, onBeforeMount, onMounted, reactive, ref, toRefs } from "vue";
import * as echarts from "echarts";
import 'dayjs/locale/zh-cn';
import dayjs,{ Dayjs } from 'dayjs';
import  DateUtil,{getPickerTime}  from '@/utils/common';
import { getCardList,getChartData,getRankingData,getOrderData,getOptionData } from '@/api/saleManage';
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';

export default defineComponent({
  name: "saleManage",
  components:{
    breadcrumb
  },
  setup() {
    // 面包屑
    const breadData = ref<any []>([
        {title:'首页',path:'#/'},
        {title:'销售管理',path:'/index/saleManage'},
        {title:'销售汇总',path:'#/'},
    ])
    
    const cardList = reactive({
      list: [
        { title: "用户数"},
        { title: "成交量（二维码+实体卡+自然流量）"},
        { title: "二维码"},
        { title: "实体卡"},
        { title: "体验会员"},
        { title: "自然流量"},
      ],
      data:{}
    });
    const query = reactive({
        team:'',
        checked:false
    })
    // picker
    const pickerFoo = ref<Array<any>>([
        {title:'清空',type:'0'},
        {title:'今天',type:'1'},
        {title:'本周',type:'2'},
        {title:'当月',type:'3'},
        {title:'今年',type:'4'},
        {title:'去年',type:'5'}
    ])
    const time = ref<Dayjs []>()
    const timeDate = reactive({
      type:'',
      time:{}
    })
    const isOpen = ref<boolean>(false)
    // table
    const dataSource = ref<Array<any>>([]);
    const columns:Array<object> =  [
      {
        title: '排名',
        dataIndex: 'num',
        key: 'num',
      },
      {
        title: '名称',
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: '总量',
        dataIndex: 'totle',
        key: 'totle',
      },
      {
        title: '实际成交',
        dataIndex: 'buy_count',
        key: 'buy_count',
      },
      {
        title: '体验会员',
        dataIndex: 'exper_count',
        key: 'exper_count',
      },
    ]
    const orderData = ref<Array<any>>([]);
    const orderColumns =  [
      {title: '时间',dataIndex: 'date_time',key: 'date_time',},
      { title: '总收益', dataIndex: 'totle', key: 'totle',},
      {title: '二维码',dataIndex: 'ewm_count', key: 'ewm_count'},
      {title: '实体卡', dataIndex: 'card_count',key: 'card_count',},
      { title: '自然流量',dataIndex: 'flow_count',key: 'flow_count'},
      {title: '体验会员',dataIndex: 'exper_count',key: 'exper_count',},
    ]
    // 下拉数据
    const optionVal = ref<Array<any>>([])
    // echarts
    const xData = ref<Array<any>>([])
    const yData = ref<Array<any>>([])

    onBeforeMount(()=>{
      timeDate.type = '3';
      setPicker('3')
      chartInitData()
      getData()
      getTable()
      getOrderTableData()
      getTeamData()
    })

    // chart
    const chartInit = () => {
      var myChart = echarts.init((document as any).getElementById("right"));
      let data: Array<string> = [
        "总计",
        "购买数",
        "二维码",
        "实体卡",
        "体验会员",
        "自然流量",
      ];
      let color: Array<string> = [
        "#5081FF",
        "#703AB7",
        "#CA5A40",
        "#67BAC3",
        "#58A55C",
        "#D0A537",
      ];
      let legendData: Array<object> = [];
      let seriesData: Array<object> = [];
      for (let i = 0; i < data.length; i++) {
        legendData.push({
          name: data[i],
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            width: 5,
          },
        });
        seriesData.push({
          name: data[i],
          type: "line",
          // stack: "Total",
          showSymbol: false,
          data: yData.value[i],
          lineStyle: {
            color: color[i],
          },
          symbolSize: 10,
          symbol: "circle",
          smooth: true,
        });
      }
      var option;
      option = {
        color: color,
        backgroundColor: "#282976",
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: legendData,
          textStyle: {
            color: "#7471A7",
          },
          top: 20,
          right: 20,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData.value,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 40,
            textStyle: {
              color: "#4A4BD1",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#30328D",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#4A4BD1",
            },
          },
        },
        series: seriesData,
      };
      myChart.setOption(option);
    };

    // picker底部点击事件
    const setPicker = (type:string):void =>{
      timeDate.type = type;
      switch (type) {
          case '0':
              time.value = [];
              break;
          case '1':
              time.value = [dayjs(),dayjs()];
              break;
          case '2':
              time.value = [dayjs(DateUtil.DateUtil.getStartDayOfWeek()),dayjs(DateUtil.DateUtil.getEndDayOfWeek())];
              break;
          case '3':
              time.value = [dayjs(DateUtil.DateUtil.getStartDayOfMonth()),dayjs(DateUtil.DateUtil.getEndDayOfMonth())];
              break;
          case '4':
              time.value = [dayjs(DateUtil.DateUtil.getYear('s',0)),dayjs(DateUtil.DateUtil.getYear('e',0))];
              break;
          case '5':
              time.value = [dayjs(DateUtil.DateUtil.getYear('s',-1)),dayjs(DateUtil.DateUtil.getYear('e',-1))];
              break;    
          default:
              break;
      }
      
      timeDate.time = getPickerTime(time.value,0)      
    }
    // 时间框的回调
    const timeChange = ():void=>{
      timeDate.type = '6';
      timeDate.time = getPickerTime(time.value,0)
      
      
    }
    // 展开时间框
    const pickerFocus = ()=>{
      isOpen.value = true;
    }
    // 收起时间框
    const openChange = (status:boolean):void =>{
      isOpen.value = status;
    }

    const close = ():void =>{
      isOpen.value = false;
      chartInitData()
      getData()
      getTable()
      getOrderTableData()
    }
   
    // 获取卡片数据
    const getData = ():void =>{
      getCardList({start_time:(timeDate.time as any).start,my_order:2,
          end_time:(timeDate.time as any).end,type:timeDate.type,agent_id:query.team}).then((res:any)=>{
            if(res.code == 200){
              cardList.data = res.data;
            }
        })
    }
    // 获取图标数据
    const chartInitData = ():void=>{
      getChartData({start_time:(timeDate.time as any).start,my_order:2, end_time:(timeDate.time as any).end,
        type:timeDate.type,agent_id:query.team}).then((res:any)=>{
        if(res.code == 200){
          xData.value = res.data.data_xtime;
          yData.value = res.data.data_echarts;
          
          chartInit();
        }
      })
    }
    // 获取团队排名数据
    const getTable = ():void =>{
      getRankingData({start_time:(timeDate.time as any).start,my_order:2, end_time:(timeDate.time as any).end,
        type:timeDate.type,agent_id:query.team}).then((res:any)=>{
          if(res.code == 200){
            dataSource.value = res.data.team_city;
            dataSource.value.forEach((item,idx)=>{
              item.num = idx+1
            })
          }
      })
    }
    // 订单数据
    const getOrderTableData = ():void =>{
      getOrderData({start_time:(timeDate.time as any).start,my_order:2, end_time:(timeDate.time as any).end,
        type:timeDate.type,agent_id:query.team}).then((res:any)=>{
          if(res.code == 200){
            orderData.value = res.data.data_order_totle;
          }
      })
    }
    // 获取团队
    const getTeamData = ():void=>{
      getOptionData().then((res:any)=>{
        if(res.code == 200){
          optionVal.value = res.data;
        }
      })
    }

    const selectChang = (val:any):void =>{
      chartInitData()
      getData()
      getTable()
      getOrderTableData()
      getTeamData()
    }

    return {
      dataSource,
      columns,
      locale,
      pickerFoo,
      ...toRefs(cardList),
      ...toRefs(query),
      isOpen,
      time,
      orderData,
      orderColumns,
      setPicker,
      timeChange,
      pickerFocus,
      close,
      openChange,
      optionVal,
      selectChang,
      breadData
    };
  },
});

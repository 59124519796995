import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";
import { setUrl } from '@/utils/common';
// 销售汇总 卡片数据
export const getCardList = (data:any): Promise<AxiosResponse<object>> =>{
    let url  = setUrl('/agent-api/api/sales_statistics?',data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 销售汇总 图标数据
export const getChartData = (data:any): Promise<AxiosResponse<object>> =>{
    let url  = setUrl('/agent-api/api/sales_statis_echarts?',data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 销售汇总 团队排名数据 ranking getTableData
export const getRankingData = (data:any): Promise<AxiosResponse<object>> =>{
    let url  = setUrl('/agent-api/api/sales_team_rank?',data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 销售汇总 订单数据
export const getOrderData = (data:any): Promise<AxiosResponse<object>> =>{
    let url  = setUrl('/agent-api/api/sales_order_data?',data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 我的销售 新增会员图表数据
export const getNewMemberData = (data:any): Promise<AxiosResponse<object>> =>{
    let url  = setUrl('/agent-api/api/sales_new_user?',data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 获取团队数据
export const getOptionData = (): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/team_user`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

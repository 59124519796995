
import { defineComponent, onMounted, reactive, ref, toRefs,Ref, watchEffect } from "vue";
export default defineComponent({
    name:"breadcrumb",
    props:{
        data:{
            type:Array,
            default:()=>{
                return []
            }
        } 
    },
    setup(props){

        const dataSource = ref<any []>([])

        watchEffect(()=>{
            dataSource.value = props.data
            // console.log(dataSource);
        })
        return{
            dataSource
        }
    }
})
